import { homeStrings } from "@/strings/home";
import { motion } from "framer-motion";

export function HeroBox() {
  return (
    <motion.div
      className="relative group overflow-hidden p-px w-full max-w-screen-xl mt-24 rounded-2xl rotating-border"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{
        ease: "easeIn",
        delay: 0,
        duration: 0.2,
      }}
    >
      <div className="relative p-4 rounded-2xl  flex justify-center ">
        <p className="text-xl md:text-3xl lg:text-4xl text-sima-orange font-sans w-full max-w-screen-xl tracking-wider leading-relaxed font-normal text-center md:text-left">
          {homeStrings.hero}
        </p>
      </div>
    </motion.div>
  );
}
