"use client";
import { Banner } from "@/components/Banner";
import { Cta } from "@/components/Cta";
import { HeroBox } from "@/components/HeroBox";
import { HomeInfoCard } from "@/components/HomeInfoCard";
import { homeStrings } from "@/strings/home";
import { Graph, Organization } from "schema-dts";
const pageStructuredMetadata: Organization = {
  "@type": "Organization",
  // url: process.env.NEXT_PUBLIC_SITE_URL,
  logo: "/logos/logo.webp",
  name: "SIMA Asphalt",
  legalName: "SIMA Asphalt Limited",
};
const structuredMetadata: Graph = {
  "@context": "https://schema.org",
  "@graph": [
    pageStructuredMetadata,
    {
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Home",
          item: "https://sima-asphalt.com",
        },
      ],
    },
  ],
};

export default function Home() {
  return (
    <>
      <script
        key="structured-data"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(structuredMetadata),
        }}
      />
      <Banner />
      <main className="flex flex-col items-center mx-auto px-4 gap-24 ">
        <HeroBox />
        <div className="flex flex-col gap-12 items-center">
          {homeStrings.sections.map((section) => (
            <HomeInfoCard key={section.title} section={section} />
          ))}
        </div>
        {homeStrings.cta ? (
          <Cta text={homeStrings.cta.text} link={homeStrings.cta.link} />
        ) : null}
      </main>
    </>
  );
}
