export const homeStrings = {
  hero: "SIMA Asphalt is determined to change the way we manufacture asphalt. Using the latest production techniques, we have developed a range of products that not only surpass our expectations, but deliver them at the lowest possible price.",
  sections: [
    {
      title: "Additives for asphalt",
      content: [
        "We are one of the top suppliers in the UK for warm mix additives, namely our LowTherm 4G product, which has consistently outperformed all competitors on the market. LowTherm 4G has proven itself in both the laboratory and the field. Over 6,000,000 tonnes of Base and Binder course with LowTherm 4G has been laid in the UK alone.",
        "We also offer a wide range of other additives, including rejuvenators and release agents, amongst others. We are constantly innovating and looking for new ways to improve the sustainability of our products and reduce their carbon footprint and to this end, we have successfully developed a new line of green, 100% plant-based rejuvenators for RAP.",
      ],
      link: {
        href: "/additives",
        text: "Find out more about our range of additives",
      },
    },

    {
      title: "Engineering products and support",
      content: [
        "SIMA Asphalt also supplies dosing systems for chemical and fibre additives. Our engineering experience is second to none and our products are installed on some the UK’s largest batch and drum mix asphalt plants. Thanks to our recent partnership with CES electrical, we offer full installation of our dosing systems, as well as lifetime maintenance and support.",
      ],
      link: {
        href: "/equipment",
        text: "Find out more about our engineering products",
      },
    },
  ],
  cta: {
    text: "Please contact us to discuss your requirements and to see how SIMA Asphalt can help your company produce the roads of tomorrow.",
    link: {
      text: "Contact us",
      href: "/contact",
    },
  },
};
