import { cn } from "@/lib/utils";
import { homeStrings } from "@/strings/home";
import { motion } from "framer-motion";
import { ArrowRightIcon } from "lucide-react";
import Link from "next/link";

export function HomeInfoCard({
  section,
}: {
  section: (typeof homeStrings.sections)[number];
}) {
  return (
    <motion.div
      key={section.title}
      className="text-zinc-50 bg-sima-grey-dark p-8 rounded-xl flex flex-row flex-wrap  sm:w-5/6 lg:w-full max-w-screen-xl shadow-sima-grey-dark shadow-xl"
      initial={{ y: 100, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      viewport={{ once: true }}
      transition={{
        ease: "easeIn",
        delay: 0,
        duration: 0.2,
      }}
    >
      <h2 className="w-full text-2xl md:text-3xl lg:text-4xl font-sans font-bold text-sima-orange">
        {section.title}
      </h2>
      {section.content.map((para, idx) => (
        <p
          key={idx}
          className={cn(
            "w-full py-4 text-md md:text-lg lg:text-xl leading-8 md:leading-10 lg:leading-10",
            section.content.length === 1 ? "md:w-full pl-0" : "md:w-1/2",
            section.content.length === 2 && idx === 0
              ? "md:border-r-zinc-50 md:border-r md:pr-12"
              : "",
            section.content.length === 2 && idx === 1 ? "md:pl-12" : "",
            section.content.length > 1 && idx === section.content.length - 1
              ? "md:last:border-r-0"
              : ""
          )}
        >
          {para}
        </p>
      ))}
      {section.link ? (
        <div className="w-full flex items-center justify-end text-right group py-4 font-bold">
          <Link
            href={section.link.href}
            className="group-hover:text-sima-orange text-zinc-50 md:text-lg lg:text-xl font-sans flex flex-row items-center transition-colors ease-out duration-500"
          >
            {section.link.text}
            <ArrowRightIcon className="h-6 w-6 ml-2 group-hover:translate-x-2 text-zinc-50 transition-all group-hover:text-sima-orange  ease-out duration-500" />
          </Link>
        </div>
      ) : null}
    </motion.div>
  );
}
