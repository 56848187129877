"use client";

import { useEffect, useState } from "react";

export function Counter({
  finalValue,
  duration,
}: {
  finalValue: number;
  duration: number;
}) {
  const [count, setCount] = useState<string>("000000");

  useEffect(() => {
    // Calculate the interval time based on the duration and final value
    const intervalTime = duration / finalValue;

    // Set interval
    const interval = setInterval(() => {
      setCount((prevCount) => {
        // If the count is less than finalValue, increment it
        const inc = finalValue > 50000 ? 93 : 23;
        const nextValue =
          parseInt(prevCount) + inc * Math.floor(Math.random() * 10);
        if (parseInt(prevCount) < finalValue) {
          if (nextValue < 10) {
            return "00000" + nextValue.toString();
          } else if (nextValue < 100) {
            return "0000" + nextValue.toString();
          } else if (nextValue < 1000) {
            return "000" + nextValue.toString();
          } else if (nextValue < 10000) {
            return "00" + nextValue.toString();
          } else if (nextValue < 100000) {
            return "0" + nextValue.toString();
          }
          return nextValue.toString();
        }
        if (nextValue > finalValue) {
          if (finalValue < 100000) {
            return "0" + finalValue.toString();
          } else {
            return finalValue.toString();
          }
        }
        // Otherwise, clear the interval and keep the count as is
        else {
          clearInterval(interval);
          return prevCount.toString();
        }
      });
    }, intervalTime);

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [finalValue, duration]);

  return (
    <div className="text-sima-grey-dark text-4xl sm:text-6xl xl:text-7xl flex flex-row  font-chivo font-semibold gap-2 md:gap-3 ">
      {count.split("").map((num, idx) => (
        <div
          className="bg-sima-grey-light/70 rounded-2xl md:rounded-[2rem] p-2 px-4  md:p-4 md:px-6 leading-none tabular-nums "
          key={idx}
        >
          {num}
        </div>
      ))}
    </div>
  );
}
