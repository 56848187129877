import Link from "next/link";
import { Button } from "./ui/button";

export function Cta({
  text,
  link,
}: {
  text: string;
  link: { href: string; text: string };
}) {
  return (
    <div className="font-sans text-zinc-50 text-3xl max-w-screen-xl px-12  flex flex-col items-center pb-24 gap-6 ">
      <p className=" text-xl md:text-2xl lg:text-3xl  text-center">{text}</p>

      <Button
        className="text-xl md:text-2xl font-bold lg:text-3xl bg-sima-orange p-6 rounded-2xl  shadow-md hover:shadow-none hover:bg-sima-orange transition-all duration-200 ease-out"
        asChild
      >
        <Link href={link.href}>{link.text}</Link>
      </Button>
    </div>
  );
}
